import { RouteComponentProps } from 'react-router';

declare global {
  /** optional `data-testid` prop */
  type DataTestIdProp = {
    'data-testid'?: string;
  };
  /** optional `className` prop */
  type ClassNameProp = {
    className?: string;
  };
}

export interface Order {
  status?: string;
  isTrial?: number;
  shipment?: Shipment;
  id?: string;
  gtuxId?: number;
  subtotal?: number;
  isSwatch?: boolean | number;
  tax?: number;
  total?: number;
  fees?: Array<Fee>;
  address?: Address;
  needsByDate?: string;
  member?: Member;
  createdAt?: string;
}

export interface Fee {
  id?: number;
  type?: string;
  amount?: number;
  taxRate?: number;
}
export interface Shipment {
  id?: number;
  cartons?: Array<Carton>;
  status?: string;
}
export interface Carton {
  type: string;
  trackingUrl: string;
  labelUrl?: string;
  isSwatch?: boolean;
}

export interface MemberForm {
  phone?: string;
  email?: string;
  lastName?: string;
  firstName?: string;
}

export interface InvitedMemberForm {
  phone?: string;
  email?: string;
  password?: string;
  lastName?: string;
  firstName?: string;
  potentialMemberId?: string;
  shortCode?: string;
  smsOptIn?: boolean;
  emailOptIn?: boolean;
}

export interface OwnerForm {
  phone?: string;
  email?: string;
  lastName?: string;
  firstName?: string;
}

export interface GtEventType {
  id?: number;
  name?: string;
}

export interface PartyRole {
  id: number;
  name: string;
  isRenting: boolean;
  gtEventType: GtEventType;
}

export interface PotentialMember {
  id?: string;
  nickname?: string;
  partyRole?: PartyRole;
  role?: Look;
  member?: Member | null;
  key?: string | number;
}

export interface MemberCard {
  id: string;
  nickname: string;
  partyRoleName: string;
  isLocked: boolean;
  isPlaceHolder: boolean;
  isPaid: boolean;
  member: Member | null;
}

export interface Member {
  id?: string;
  paying?: boolean;
  look?: string;
  isOwner?: boolean;
  isCreator?: boolean;
  isInvited?: boolean;
  isMeasured?: boolean;
  isPaid?: boolean;
  isReceived?: boolean;
  isReserved?: boolean;
  isReturned?: boolean;
  isShipped?: boolean;
  trackingCode?: string;
  accountId?: string;
  event?: GTEvent;
  role?: Look;
  customer?: Customer;
  orders?: Array<Order>;
  gtEvent?: GTEvent;
  potentialMember?: PotentialMember | null;
  productToMember?: Array<ProductToMember>;
}

export interface ProductToMember {
  isPaidFor?: boolean;
  isDeleted?: boolean;
  product: Product;
}

export interface MemberOrderDetails {
  [key: string]: {
    discount: number;
    shipping_cost: number;
    damage_waiver_fee: number;
  };
}

export interface CustomerClonedEventPromo {
  id: string;
  clonedEventId: string;
  sourceEventId: string;
  promotion: ClonedEventPromo;
}

export interface ClonedEventPromo {
  id: string;
  code: string;
  discountAmount: string;
  orders: Order[];
  active: boolean;
}

export interface CampaignAttributions {
  key: string;
  value: string;
}

export interface LastCampaign {
  attributions?: Array<CampaignAttributions>;
}

export interface GTEventForm {
  day?: number;
  year?: number;
  partyRoleId?: number;
  type?: string;
  month?: number;
  source?: string;
  name?: string;
  metadata?: string;
  usedDefaultEventDate?: boolean;
}

export interface GTEvent {
  id?: string;
  startDate?: string;
  name?: string;
  eventType?: string;
  type?: string;
  role?: string;
  year?: number;
  day?: number;
  month?: number;
  accountId?: string;
  isTrial?: boolean;
  members?: Array<Member>;
  roles?: Array<Look>;
  status?: string;
  lastCampaign?: LastCampaign;
  metadata?: string;
  source?: string;
  potentialMembers?: PotentialMember[];
  partyRoles?: PartyRole[];
  shortCode?: ShortCode;
  gtEventType?: GtEventType;
  gtuxId?: number;
  clonedEvent?: GTEvent;
  partySize?: number;
  usedDefaultEventDate?: boolean;
}

export interface ShortCode {
  id: string;
  code: string;
  gtEvent?: GTEvent;
}

export interface Metadata {
  campaignId: number;
}

export interface Organization {
  id: number;
}

export interface Customer {
  id?: string;
  password?: string;
  email?: string;
  phone?: string;
  firstName?: string;
  lastName?: string;
  addresses?: Array<{ id: string }>;
  isRegistrationComplete?: boolean;
  primaryEventId?: string;
  primaryEvent?: GTEvent;
  organizationId?: number;
  jwt?: string;
  organization?: Organization;
  smsOptIn?: boolean;
  emailOptIn?: boolean;
  state?: string;
  isCx?: boolean;
}

export interface CustomerForm {
  email?: string;
  password?: string;
  firstName?: string;
  lastName?: string;
  phone?: string;
  smsOptIn?: boolean;
  emailOptIn?: boolean;
}

export interface Address {
  id?: number;
  firstName: string;
  lastName: string;
  addressLine1: string;
  addressLine2?: string;
  city: string;
  state: string;
  zip: string;
  country: string;
  isDefault: boolean;
  streetAddress?: string;
}

export interface Look {
  id: string;
  name?: string;
  items?: Array<Product>;
  bundles?: Array<Bundle>;
  locked?: boolean;
  members?: Array<Member>;
  potentialMembers?: PotentialMember[];
  shareLinkCode?: string;
}

export interface LookPreviewItem {
  id: number;
  category: string;
  activeItem: Item | null;
  isBundle: boolean;
  icon: string;
  placeholder: string;
}

export interface GalleryItem {
  id: number;
  category: string;
  mktCopy: string;
  filters: Filter;
}

export interface Step {
  step: string;
  url?: string;
  fork?: Array<Array<Step>>;
}

export interface Flow {
  [key: string]: string[] | Array<Flow | string | Flow[]> | Flow | string | Flow[];
  stream: string[];
  outflow: Array<Flow | string> | Flow | string | Flow[];
}

export interface ItemProperties {
  id: number;
  category?: string;
  url_slug?: string;
  urlSlug?: string;
  displayName?: string;
  name?: string;
  cost?: string;
  unitCost?: number | null;
  costToCustomer?: number | null;
  display_index?: number | string | null;
  displayable?: boolean;
  pattern?: string | null;
  type?: string;
  color?: string;
  colorGroup?: string | null;
  colorCode?: string | null;
  sku: string;
  description?: string | null;
  shortDescription?: string | null;
  rentalTerms?: string | null;
  is_active?: boolean;
  isActive?: boolean;
  blockoutDates?: Array<BlockoutDate>;
  blockout_dates?: Array<BlockoutDate>;
  details?: Array<ProductDetail | BundleDetail>;
  swatch?: Product | null;
  catalogNumber?: string | null;
  catalogName?: string | null;
  available_in_slim?: boolean;
  available_in_boys?: boolean;
  media?: (ProductMedia | BundleMedia)[];
  attributePrimary?: string;
  attributeSecondary?: string;
  attributeTertiary?: string;
  rentalBundle?: Bundle;
  is_retail?: boolean;
  isRetail?: boolean;
  retail_bundle?: Bundle;
  retailBundle?: Bundle;
}

/**
 *  Accessing properties on union types that aren't accessible
 *  on both (e.g. (Item).bundleId) are tricky
 *  so you have to cast the value to one or the other
 *  e.g. (<Bundle>item).val.
 **/

export interface Product extends ItemProperties {
  pivot?: ProductBundlePivot;
}

export interface Bundle extends ItemProperties {
  products?: Array<Product>;
  looks?: Array<Bundle>;
}

export interface RetailBundleReference {
  id: number | null;
}

export type Item = Product | Bundle;

export interface ProductBundlePivot {
  product_id?: number;
  bundle_id?: number;
}

export interface ProductDetail {
  id: number;
  product_id: number;
  detail?: string;
  created_at: string;
  updated_at: string;
}

export interface BundleDetail {
  id: number;
  bundle_id: number;
  detail?: string;
  created_at: string;
  updated_at: string;
}

export interface BlockoutDate {
  id?: number;
  blockable_type?: string;
  availability?: string;
  blockable_id?: number;
  start_date?: string;
  end_date?: string;
  startDate?: string;
  endDate?: string;
  created_at?: string;
  updated_at?: string;
}

export interface Category {
  displayName: string;
  name: string;
  slug: string;
  filters: Array<string>;
}

export interface Filter {
  categories: string[];
  patterns: string[];
  colors: string[];
}

export interface CategoryState {
  name: string;
  page: number;
  filters: Filter;
}

export interface Media {
  label?: string;
  url?: string;
  display_name?: string;
  description?: string | null;
  display_index?: number;
  created_at?: string;
  modified_at?: string | null;
  created_by?: number;
  modified_by?: number | null;
}

export interface ProductMedia extends Media {
  product_media_id?: number;
  product_id?: number;
}

export interface BundleMedia extends Media {
  bundle_media_id?: number;
  bundle_id?: number;
}

export interface CheckoutRequest {
  [key: string]: string | number | boolean | undefined;
  accountId: string;
  eventId: string;
  eventDate: string;
  needsBy?: string;
  shipFirstName: string;
  shipLastName: string;
  shipAddressLine1: string;
  shipAddressLine2: string;
  shipAddressCity: string;
  shipAddressState: string;
  shipAddressZip: string;
  paymentToken: string;
  customInviteMsg: string;
  promoCode: string;
  saveShipAddress: boolean;
  rushCost: number;
  waiveDamageWaiverFee: boolean;
  orderMetaData: string | undefined;
}

export const flows: { [index: string]: Array<Step> } = {
  signup: [{ step: 'email' }, { step: 'password' }],
  editUser: [{ step: 'email' }, { step: 'phone' }, { step: 'firstName' }, { step: 'lastName' }],
  createEvent: [{ step: 'role' }, { step: 'year' }, { step: 'month' }, { step: 'day' }],
  editEvent: [{ step: 'year' }, { step: 'month' }, { step: 'day' }],
  member: [{ step: 'email' }, { step: 'firstName' }, { step: 'lastName' }, { step: 'phone' }, { step: 'look' }],
  fit: [
    { step: 'height' },
    { step: 'weight' },
    { step: 'age' },
    { step: 'shoe' },
    { step: 'jeanwaist' },
    { step: 'build' },
    { step: 'preference' },
  ],
  optionalFit: [
    { step: 'neck' },
    { step: 'overarm' },
    { step: 'chest' },
    { step: 'sleeve' },
    { step: 'stomach' },
    { step: 'waist' },
    { step: 'outseam' },
  ],
  swatch: [
    { step: 'build', url: '/swatch/build' },
    { step: 'shipping', url: '/swatch/shipping' },
    { step: 'summary', url: '/swatch/summary' },
    { step: 'confirmation', url: '/swatch/confirmation' },
  ],

  swatchOrder: [{ step: 'shipping' }, { step: 'summary' }, { step: 'confirmation' }],
  hto: [
    { step: 'build', url: '/hto/looks/build' },
    { step: 'shipping', url: '/hto/shipping' },
    { step: 'payment', url: '/hto/payment' },
    { step: 'confirmation', url: '/hto/confirmation' },
  ],
  htoOrder: [{ step: 'payment' }, { step: 'confirmation' }],
};

export interface SignificantOther {
  id?: string;
  email?: string;
}

export interface GTUser {
  id: string;
  firstName?: string;
  lastName?: string;
  email: string;
  phone: string;
  smsOptIn: boolean;
  primaryEventId: string;
  jwt?: string;
  first_name?: string;
  last_name?: string;
  emailOptIn?: boolean;
  state?: string;
  isCx?: boolean;
}

export interface SessionUser {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  primaryEventId: string;
  smsOptIn?: boolean;
  emailOptIn: boolean;
  state: string;
  isCx: boolean;
  refreshToken: string;
  jwt: string;
}

export interface RequestUser extends SessionUser {
  first_name?: string;
  last_name?: string;
  primary_event_id?: string;
  sms_opt_in?: boolean;
  email_opt_in?: boolean;
  is_cx?: boolean;
}

export interface SegmentUser {
  id: string;
  firstName?: string;
  lastName?: string;
  email: string;
  phone: string;
  smsOptIn: boolean;
  emailOptIn?: boolean;
  primaryEventId: string;
  first_name?: string;
  last_name?: string;
  state?: string;
  country?: string;
  zipCode?: string;
  Role?: string;
  significantOther?: SignificantOther;
}

export interface WindowEvent {
  id: string;
  name: string;
  gtuxId: number;
  isTrial: number;
  status: string;
  startDate: string;
  type: string;
  isOwner: number;
  memberId: string;
}

interface TTDUniversalPixelApi {
  init: (key: string, array: string[], url: string) => void;
}

interface Gtag {
  (command: 'config', target: string, params?: { [key: string]: unknown }): void;
  (command: 'get', target: string, fieldName: string, callback: (field?: string) => void): void;
  (command: 'set', parameters: { [key: string]: unknown }): void;
  (command: 'event', eventName: string, params?: { [key: string]: unknown }): void;
}

/**
 * An object describing an Optimizely experiment that a user belongs to
 *
 * @see https://docs.developers.optimizely.com/web-experimentation/reference/state
 */
export type Experiment = {
  id: string;

  /**
   * An object describing the variation that the user was bucketed into for this
   * experiment, or `null` if they weren't bucketed
   */
  variation: null | ExperimentVariation;
};

/**
 * Maps an experiment ID to an object describing the state of the experiment for
 * the current user
 */
export type ExperimentByID = {
  [key: string]: Experiment;
};

export type ExperimentVariation = {
  id: string;
};

/**
 * An object containing several functions that return information about things like
 * what experiments are active, the current user and their buckets, etc.
 *
 * @see https://docs.developers.optimizely.com/web-experimentation/reference/state
 */
export type OptimizelyState = {
  getActiveExperimentIds(): string[];
  getExperimentStates(filter?: { isActive: boolean }): ExperimentByID;
};

export type CampaignDecidedEvent = {
  data: {
    decision: {
      experimentId: string;
      variationId: string;
    };
  };
};

export type OnCampaignDecided = (event: CampaignDecidedEvent) => any;

/**
 * Optimizely's JavaScript snippet for the Web Experimentation API
 *
 * @see https://docs.developers.optimizely.com/web-experimentation/docs/getting-started
 */
export type OptimizelySnippet = {
  initialized: boolean;
  get(argument: 'state'): OptimizelyState;
  push(options: OptimizelyPushOptions): any;
};

type OptimizelyPushOptions =
  | OptimizelyAddCampaignDecidedListener
  | OptimizelyUserAttributesTracking
  | OptimizelyHoldOrSendEvents;

type OptimizelyAddCampaignDecidedListener = {
  type: 'addListener';
  filter: {
    type: 'lifecycle';
    name: 'campaignDecided';
  };
  handler: OnCampaignDecided;
};

type OptimizelyUserAttributesTracking = {
  type: 'user';
  attributes: {
    [key: string]: string;
  };
};

type OptimizelyHoldOrSendEvents = {
  type: 'holdEvents' | 'sendEvents';
};

declare global {
  var gt: {
    user: GTUser;
  };

  var gtag: Gtag;

  var optimizely: OptimizelySnippet;

  interface Window {
    gt: {
      user: GTUser;
      env: string;
      orgId: number;
      events?: WindowEvent[];
      lookBuilderAssetsBaseUrl: string;
      ttclid?: string | null;
    };
    trackers: {
      unbounceconvert: () => any;
    };
    organization: string;
    STRIPE_PUBLIC_KEY: string;
    analytics: SegmentAnalytics.AnalyticsJS;
    optimizely: OptimizelySnippet;
    ttd_dom_ready: (callback: () => void) => void;
    TTDUniversalPixelApi: new () => TTDUniversalPixelApi;
    _ubaq: string[];
  }
}

export interface EventRouteProps<P> extends RouteComponentProps<P> {
  eventId: string;
}

export type FlowStep = (urlParams?: string, outflowIndex?: number, currentUrl?: string) => Promise<void>;

export interface FlowEventRouteProps<P> extends EventRouteProps<P> {
  flow?: FlowStep;
}

export interface FlowRouteProps<P> extends RouteComponentProps<P> {
  flow?: FlowStep;
}

export interface SVGProps {
  className?: string;
  style?: object;
  width?: string;
  height?: string;
  color?: string;
}

export type FitShoeTypes = 'M' | 'Y' | 'K';
export type FitBuildStomach = 'flat' | 'average' | 'rounded' | undefined;
export type FitbuildProfile = 'skinny' | 'average' | 'muscular' | undefined;
export type FitOutfitPreferences = 'modern' | 'slim' | undefined;
export type FitActions =
  | 'HEIGHT_SET'
  | 'WEIGHT_SET'
  | 'AGE_SET'
  | 'SHOE_SET'
  | 'WAIST_IN_SET'
  | 'BUILD_SET'
  | 'PREFERENCE_SET'
  | 'NECK_SET'
  | 'OVERARM_SET'
  | 'CHEST_SET'
  | 'ARMLENGTH_SET'
  | 'STOMACH_SET'
  | 'WAIST_SET'
  | 'MEASURED_BY_TAILOR_SET'
  | 'OUTSEAM_SET';

export interface FitShoeSizes {
  M: Array<number>;
  Y: Array<number>;
  K: Array<number>;
}

export interface RequestMeasurementsData {
  height: string;
  weight: string;
  neck: string;
  chest: string;
  overarm: string;
  armLength: string;
  stomach: string;
  waist: string;
  outseam: string;
  shoeSize: string;
  shoeWidth: FitShoeTypes;
  fitPreference: FitOutfitPreferences;
  buildProfile: FitbuildProfile;
  stomachProfile: FitBuildStomach;
  pantWaist: string;
  age: string;
  algorithmId?: number;
}

export interface PredictMeasurementsV2Data {
  height: string;
  weight: string;
  pantWaist: string;
}

export interface CreateOrUpdateMeasurementsData {
  id?: string;
  height: string;
  weight: string;
  neck: string;
  chest: string;
  overarm: string;
  armLength: string;
  stomach: string;
  waist: string;
  outseam: string;
  shoeSize: string;
  shoeWidth: FitShoeTypes;
  fitPreference: FitOutfitPreferences;
  buildProfile: FitbuildProfile;
  stomachProfile: FitBuildStomach;
  pantWaist: string;
  age: string;
  algorithmId?: number;
  cxComments?: string;
}

export interface ValidateMeasurementsData {
  height: string;
  weight: string;
  neck: string;
  chest: string;
  overarm: string;
  arm_length: string;
  stomach: string;
  waist: string;
  outseam: string;
  shoe_size: string;
  shoe_width: FitShoeTypes;
  fit_preference: FitOutfitPreferences;
  build_profile: FitbuildProfile;
  stomach_profile: FitBuildStomach;
  pant_waist: string;
  age: string;
  algorithm_id: number;
}

export interface RequestMeasurementsResponse {
  arm_length: string;
  chest: string;
  neck: string;
  outseam: string;
  overarm: string;
  stomach: string;
  waist: string;
  algorithm_id: number;
}

export interface GetMeasurementsResponse {
  id: string;
  height: number;
  weight: number;
  shoeSize: number;
  shoeWidth: FitShoeTypes;
  age: number;
  stomachProfile: FitBuildStomach;
  buildProfile: FitbuildProfile;
  fitPreference: FitOutfitPreferences;
  pantWaist: number;
  overarm: number;
  neck: number;
  armLength: number;
  chest: number;
  stomach: number;
  waist: number;
  outseam: number;
  algorithmId: number;
}

export interface CreateOrUpdateMeasurementsResponse {
  createOrUpdateMeasurement: {
    id: string;
  };
}

export interface FitData {
  id?: string;
  buildProfile: FitbuildProfile;
  buildStomach: FitBuildStomach;
  heightFt?: number;
  heightIn?: number;
  age: string;
  measuredByTailor?: boolean;
  preference?: FitOutfitPreferences;
  shoeSize?: number;
  shoeType: FitShoeTypes;
  pantWaist: string;
  weight: string;
  neck: string;
  chest: string;
  overarm: string;
  armLength: string;
  stomach: string;
  outseam: string;
  waist: string;
  algorithmId?: number;
  cxComments?: string;
}

export interface filters {
  categoryFilters: Array<string>;
  colorFilters: Array<string>;
  patternFilters: Array<string>;
}

export interface PageViewedProps {
  title?: string;
  url?: string;
  path?: string;
  referrer?: string;
  ttclid?: string | null;
}

export type SideCarType = 'list' | 'gallery' | 'details';
export type LookBuilderViewedType = 'exploded' | 'list';
export type LookBuilderViewedFillType = 'filled' | 'empty';

export interface LookBuilderViewedProps {
  lookViewedType: LookBuilderViewedType;
  lookViewedFillType: LookBuilderViewedFillType;
}

export interface EventCreated extends GTEvent {
  partyRoleId?: number;
  partySize?: number;
  creatorEmail: string;
}

export interface SideCar {
  car: 'list' | 'gallery' | 'details';
  category: string | null;
  id: number | null;
}

export interface GlobalContextTyping {
  brand: 'menguin' | 'gentux';
  signUpUrl: string;
  loginUrl: string;
}

export type BuildLooksUIState =
  | 'loading'
  | 'lookNotFound'
  | 'lookLoaded'
  | 'eventNotFound'
  | 'removeItemFromLookError'
  | 'removingItem';

export interface JsonResponse<T extends any> extends Response {
  json(): Promise<T>;
}

export interface Screens {
  xs: string;
  sm: string;
  md: string;
  lg: string;
  xl: string;
}

export type SVG = {
  fill?: string;
  width?: string;
  height?: string;
  viewBox?: string;
  className?: string;
};

export type Image = {
  src: string;
  alt?: string;
};

export enum DeviceType {
  Phone = 'phone',
  Tablet = 'tablet',
  Desktop = 'desktop',
}

export type Device = {
  type: DeviceType;
};

export type ShareData = {
  lookId: string;
  lookName: string;
  title: string;
  text: string;
  url: string;
};

export type ItemList = {
  jacketAndPants?: string;
  shirt?: string;
  tie?: string;
  vestAndCummerbund?: string;
  pocketSquare?: string;
  cufflinks?: string;
  beltAndSuspenders?: string;
  shoes?: string;
  socks?: string;
  lapelPin?: string;
};

export type LookEdited = {
  lookId: string;
  added: ItemList;
  changed: ItemList;
  removed: ItemList;
};
