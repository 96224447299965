import Auth from '../services/Auth';
import { format } from 'date-fns';
import { getCookie, getStringCookie, isGroomOrBride } from './utils';
import { event as pixelEvent, account, page } from './pixels';
import {
  Bundle,
  Customer,
  EventCreated,
  Filter,
  GTEvent,
  Item,
  Look,
  LookBuilderViewedProps,
  LookEdited,
  Member,
  PageViewedProps,
  Product,
  SegmentUser,
  PotentialMember,
} from '../types';
import { withTikTokClickId } from './TikTok';
import { excludedEventTypes } from '../static/metrics';
import { getOptimizelyObject } from '../services/optimizely';
import PreviewStore from '../stores/look-builder/PreviewStore';
import { Participant } from '../event-flow/components/AssignMemberCard';

const segmentIsLoaded = () => window.analytics !== undefined;

const formatAsSegmentDate = (date: Date | string) => format(date, 'YYYY-MM-DD HH:mm:ss');

const trackFn = (action: string, data?: Object, i = 0) => {
  if (segmentIsLoaded()) {
    if (data === null) {
      window.analytics.track(action);
      return true;
    }
    window.analytics.track(action, data);
    return true;
  }
  if (i < 5) {
    const n = i + 1;
    setTimeout(() => trackFn(action, data, n), 500);
  }
  return false;
};

const track = {
  event: {
    created: (data?: Object) => trackFn('Event Created', data),
    updated: (data?: Object) => trackFn('Event Updated', data),
  },
  fit: {
    completed: (data?: Object) => trackFn('Fit Completed', data),
    started: (data?: Object) => trackFn('Fit Started', data),
    updated: (data?: Object) => trackFn('Fit Updated', data),
  },
  fitProfile: {
    completed: (data?: Object) => trackFn('Fit Profile Complete', data),
    updated: (data?: Object) => trackFn('Fit Profile  Updated', data),
  },
  invitations: {
    sent: (data?: Object) => trackFn('Invitations Sent', data),
  },
  look: {
    builder: (data?: object) => trackFn('Look Builder Viewed', data),
    created: (data?: Object) => trackFn('Look Created', data),
    customized: (data?: object) => trackFn('Random Look Customized', data),
    deleted: (data?: Object) => trackFn('Look Deleted', data),
    saved: (data?: Object) => trackFn('Look Saved', data),
    updated: (data?: Object) => trackFn('Look Updated', data),
    shareBtnClick: (data?: object) => trackFn('Share Button Clicked', data),
    shareCopyLinkClick: (data?: object) => trackFn('Copy Share Link Clicked', data),
    sharedLink: (data?: object) => trackFn('Shared Look Link', data),
    shareSmsClick: (data?: object) => trackFn('Text Share Link Clicked', data),
    edited: (data?: object) => trackFn('Look Edited', data),
  },
  member: {
    created: (data?: Object) => trackFn('Member Created', data),
    invited: (data?: Object) => trackFn('Member Invited', data),
    deleted: (data?: Object) => trackFn('Member Deleted', data),
  },
  product: {
    added: (data?: Object) => trackFn('Product Added', data),
    removed: (data?: Object) => trackFn('Product Removed', data),
    viewed: (data?: Object) => trackFn('Product Viewed', data),
  },
  user: {
    invitedMembers: (data?: Object) => trackFn('User Invited Members', data),
    loggedIn: (data?: Object) => trackFn('User Logged In', data),
    loggedOut: (data?: Object) => trackFn('User Logged Out', data),
    registered: (data?: Object) => trackFn('User Registered', data),
    updated: (data?: Object) => trackFn('User Updated', data),
    brideSignUp: (data?: Object) => trackFn('Bride SignUp', data),
    groomSignup: (data?: Object) => trackFn('Groom SignUp', data),
    cxLoggedIn: (data?: Object) => trackFn('CX Logged In As Customer', data),
    updatedPreferences: (data?: Object) => trackFn('Marketing Preferences Updated', data),
    welcomePacketRequest: (data?: Object) => trackFn('Welcome Packet Requested', data),
    welcomePacketPageview: (data?: Object) => trackFn('Welcome Packet Pageview', data),
    howDidYouHearAboutUsAnswered: (data?: Object) => trackFn('HDYHAU Answered', data),
  },
  promo: {
    removed: (data?: Object) => trackFn('Coupon Removed', data),
  },
  checkout: {
    started: (data?: Object) => trackFn('Checkout Started', data),
    paymentInfo: {
      entered: (data?: Object) => trackFn('Payment Info Entered', data),
    },
  },
  order: {
    completed: (data?: Object) => trackFn('Order Completed', data),
  },
  paymentInfo: {
    entered: (data?: Object) => trackFn('Payment Info Entered', data),
  },
  productList: {
    viewed: (data?: Object) => trackFn('Product List Viewed', data),
    filtered: (data?: Object) => trackFn('Product List Filtered', data),
  },
  participant: {
    added: (data?: Object) => trackFn('Member Added', data),
    removed: (data?: Object) => trackFn('Member Removed', data),
    reassigned: (data?: Object) => trackFn('Member Reassigned', data),
  },
};

export const accountCreated = (customer: Customer) => {
  const latestLookUrl = window.localStorage.getItem('latestLookUrl');

  try {
    window.analytics.identify(
      customer.id!,
      {
        email: customer.email,
        registrationDate: formatAsSegmentDate(new Date()),
        first_click: getCookie('first_click_v1'),
        last_click: getCookie('last_click_v1'),
        firstName: customer.firstName,
        lastName: customer.lastName,
        phone: customer.phone,
        smsOptIn: customer.smsOptIn,
        primaryEventId: customer.primaryEventId,
        emailOptIn: customer.emailOptIn,
        state: customer.state,
        country: 'United States',
        ...(latestLookUrl && { latestLookUrl }),
      },
      {
        integrations: {
          All: true,
        },
      }
    );

    const campaignId = Number(getStringCookie('iterableEmailCampaignId')) || null;

    track.user.registered({
      campaignId,
      email: customer.email,
      registrationDate: formatAsSegmentDate(new Date()),
    });

    account.created();

    if (latestLookUrl) {
      window.localStorage.removeItem('latestLookUrl');
    }
  } catch (e) {
    console.error(e);
  }
};

export const buildCompositeImageFromSkus = (productSkus: string[]) => {
  return fetch('/api/build-composites', {
    method: 'POST',
    body: JSON.stringify({
      product_skus: productSkus,
    }),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
};

export const lookSaved = async (products: Item[], eventId: string, lookUpdated: boolean) => {
  try {
    const bundle = products.filter((p) => p.category === 'preconfigured')[0] as Bundle;
    const bundleId = bundle !== undefined ? bundle.id : undefined;

    // Build up an array of skus, including the products on a bundle.
    // We need to handle catalogNumber & sku as it could be either depending on
    // whether we're updating or saving a new look
    let productSkus: string[] = [];
    products.forEach((product) => {
      if (product.category !== 'preconfigured') {
        if (product.category === 'Shirt') {
          const collarShadowUrl = getShirtCollarShadowUrl(product);
          if (collarShadowUrl) {
            productSkus.push(collarShadowUrl);
          }
        }
        productSkus.push(product.catalogNumber || product.sku);
      }
    });

    if (bundle && bundle.products) {
      bundle.products.forEach((product) => {
        productSkus.push(product.catalogNumber || product.sku);
      });
    }
    const swatchesAsQueryParams = products
      .map((item) => item.swatch && (item.swatch.catalogNumber || item.swatch.sku))
      .filter((str) => str !== null)
      .join(',');

    const productsSaved = products.map((product) => {
      return {
        product_id: product.id,
        name: product.displayName,
        sku:
          product.type === 'tuxedo' || product.type === 'suit'
            ? (product as Bundle).products!.map((p) => p.sku).filter((sku) => sku.charAt(0) === '1')[0]
            : product.catalogNumber,
        category: product.category,
        price: parseFloat(product.cost!).toFixed(2),
        currency: 'USD',
        brand: 'Gentux',
        image_url: getProductImageUrl(product),
      };
    });

    const data = {
      products: productsSaved,
      created_at: formatAsSegmentDate(new Date()),
      event_id: eventId,
      product_skus: productSkus,
      bundle_id: bundleId,
      campaignId:
        getStringCookie('iterableEmailCampaignId') !== undefined
          ? Number(getStringCookie('iterableEmailCampaignId'))
          : null,
    };

    if (window.gt.orgId === 1) {
      const res = await buildCompositeImageFromSkus(productSkus);
      const cleanProductSkus = productSkus.filter((sku) => !sku.includes('.png'));

      const resData = await res.json();
      if (!window.gt.user) {
        window.localStorage.setItem('latestLookUrl', resData.imageUrl);
      } else {
        window.analytics.identify(window.gt.user.id, {
          latestLookUrl: resData.imageUrl,
        });
      }

      const updatedData = {
        ...data,
        hto_url: `${
          process.env.REACT_APP_LOCAL_URL
        }/hto/looks/build?bundle_ids=${bundleId}&product_skus=${cleanProductSkus.join()}`,
        swatch_url: `${process.env.REACT_APP_LOCAL_URL}/swatch/build?swatches=${swatchesAsQueryParams}`,
        image_url: resData.imageUrl,
      };

      track.look.saved(updatedData);
      if (lookUpdated) {
        track.look.updated(updatedData);
      }
    } else {
      track.look.saved(data);
      if (lookUpdated) {
        track.look.updated(data);
      }
    }
  } catch (e) {
    console.error(e);
  }
};

export const getShirtCollarShadowUrl = (shirt: Item) => {
  let shirtCollar = null;
  if (shirt.displayName!.includes('Wing')) {
    shirtCollar = 'shadow-shirt_wingtip_shadows.png';
  }
  if (shirt.displayName!.includes('Point')) {
    shirtCollar = 'shadow-shirt_point_shadows.png';
  }
  if (shirt.displayName!.includes('Spread')) {
    shirtCollar = 'shadow-shirt_spread_shadows.png';
  }
  return shirtCollar;
};

export const productListFiltered = (category: string, filters: Filter, products: Product[]) => {
  try {
    const filteredProducts = products.map((product) => ({
      product_id: product.id,
      name: product.displayName,
      sku: getSkus(product).join(),
      category: product.category,
      price: parseFloat(product.cost!).toFixed(2),
      currency: 'USD',
      brand: window.gt.orgId === 1 ? 'Gentux' : 'Menguin',
      image_url: getProductImageUrl(product),
    }));

    let filterList = [];

    filters.colors.length > 0 &&
      filterList.push({
        type: 'color',
        value: filters.colors.join(),
      });

    filters.patterns.length > 0 &&
      filterList.push({
        type: 'pattern',
        value: filters.patterns.join(),
      });

    filters.categories.length > 0 &&
      filterList.push({
        type: 'category',
        value: filters.categories.join(),
      });

    track.productList.filtered({
      filters: filterList,
      category: category,
      products: filteredProducts,
    });
  } catch (e) {
    console.error(e);
  }
};

export const updateAccount = (updatedInfo: SegmentUser) => {
  try {
    const user = Auth.user();
    let data = {
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      phone: user.phone,
      smsOptIn: user.smsOptIn,
      primaryEventId: user.primaryEventId,
      emailOptIn: updatedInfo.emailOptIn,
      state: updatedInfo.state,
      country: 'United States',
      zipCode: updatedInfo.zipCode,
      ...(updatedInfo.Role && { isBride: updatedInfo.Role.toLowerCase().includes('bride') }),
      ...(updatedInfo.Role && { isGroom: updatedInfo.Role.toLowerCase().includes('groom') }),
      significantOther: {
        ...(updatedInfo.significantOther && { email: updatedInfo.significantOther.email }),
      },
    };

    window.analytics.identify(user.id, data, {
      integrations: {
        All: true,
        Iterable: {
          phoneNumber: window.gt.user.phone, // for iterable
        },
      },
    });
    track.user.updated();
  } catch (e) {
    console.error(e);
  }
};

export const segmentIdentify = async (user: SegmentUser) => {
  try {
    updateAccount(user);
  } catch (error) {
    throw new Error('User updated segment tracking failed');
  }
};

export const updatedPreferences = (
  email: string,
  preferences: { emailOptIn?: boolean; smsOptIn?: boolean; state: string }
) => {
  try {
    const userId = Auth.userId();

    if (!userId) {
      throw new Error(`Attempting to call updatedPreferences() from an unauthenticated context`);
    }

    track.user.updatedPreferences({ ...preferences, email });

    window.analytics.identify(userId, {
      emailOptIn: Number(preferences.emailOptIn),
      smsOptIn: Number(preferences.smsOptIn),
      state: preferences.state,
    });
  } catch (e) {
    console.error(e);
  }
};

export const loggedIn = () => {
  try {
    track.user.loggedIn({
      campaignId:
        getStringCookie('iterableEmailCampaignId') !== undefined
          ? Number(getStringCookie('iterableEmailCampaignId'))
          : null,
    });
  } catch (e) {
    console.error(e);
  }
};

export const loggedOut = () => {
  try {
    track.user.loggedOut();
  } catch (e) {
    console.error(e);
  }
};

export const eventCreated = (event: EventCreated) => {
  try {
    const eventType = event.eventType ?? '';

    const eventShouldNotBeTracked = excludedEventTypes.includes(eventType.toLowerCase());

    if (eventShouldNotBeTracked) {
      return;
    }

    const eventObj = withTikTokClickId({
      id: event.id,
      role: event.role,
      name: event.name,
      isTrial: event.isTrial,
      eventType: event.eventType,
      eventDate: formatAsSegmentDate(event.startDate!),
      eventPartySize: Number(event.partySize) || 1,
      memberCount: event.members?.length || 1,
      creatorEmail: event.creatorEmail,
      // This is mapped through Segment's Ads destination to be
      // the transaction_id for a click conversion in Google Ads
      order_id: event.id,
    });

    track.event.created(eventObj);

    if (window.gt.orgId === 1) {
      pixelEvent.created(event);
    }

    if (event.usedDefaultEventDate) {
      window.analytics.track('Used Default Event Date');
    }

    if (isGroomOrBride(event)) {
      trackBrideGroomSignup(event.partyRoleId!);
    }

    if (window.gt.user && window.analytics) {
      window.analytics.identify(window.gt.user.id, {
        primaryEventId: window.gt.user.primaryEventId,
      });
    }

    getOptimizelyObject().then((optimizely) => {
      optimizely.push({
        type: 'user',
        attributes: {
          'Event Created': 'yes',
        },
      });
    });
  } catch (e) {
    console.error(e);
  }
};

export const eventUpdated = (event: GTEvent) => {
  try {
    track.event.updated({
      id: event.id,
      role: event.role,
      name: event.name,
      isTrial: event.isTrial,
      eventType: event.eventType,
      eventDate: formatAsSegmentDate(event.startDate!!),
      eventPartySize: Number(event.partySize),
      memberCount: event.members && event.members.length,
    });
  } catch (e) {
    console.error(e);
  }
};

export const swatchesOrdered = () => {
  try {
    if (Auth.user()) {
      trackFn('Swatches Ordered');
    }
  } catch (e) {
    console.error(e);
  }
};

export const htoOrdered = () => {
  try {
    if (Auth.user()) {
      trackFn('Home Trial Ordered');
    }
  } catch (e) {
    console.error(e);
  }
};

export const measuringTapeOrdered = () => {
  try {
    if (segmentIsLoaded()) {
      window.analytics.track('Measuring Tape Ordered');
    }
  } catch (e) {
    console.error(e);
  }
};

export const memberCreated = (event: GTEvent, member: Member, role: Look) => {
  try {
    track.member.created({
      eventId: event.id,
      eventName: event.name,
      eventDate: formatAsSegmentDate(event.startDate!),
      memberId: member.id,
      memberEmail: member.customer!.email,
      role: role.name,
      isOwner: member.isOwner,
    });
  } catch (e) {
    console.error(e);
  }
};

export const memberInvited = (event: GTEvent, member: Member) => {
  try {
    track.member.invited({
      eventId: event.id,
      eventName: event.name,
      eventDate: formatAsSegmentDate(event.startDate!),
      eventType: event.eventType,
      invitedEmail: member.customer!.email,
      isOwner: member.isOwner,
    });
  } catch (e) {
    console.error(e);
  }
};

export const pageViewed = (name?: string, properties?: PageViewedProps) => {
  try {
    properties = withTikTokClickId(properties ?? {});

    // Segment page load tracking
    window.analytics.page(name, properties);

    if (window.gt.orgId === 1) {
      // Page load tracking for all other pixels
      page.loaded();
    }
  } catch (e) {
    console.error(e);
  }
};

export const productAddedToCart = (product: Item) => {
  try {
    const skus = getSkus(product);
    track.product.added({
      skus,
      price: product.cost,
    });
  } catch (e) {
    console.error(e);
  }
};

export const productRemovedFromCart = (product: Product | Item | Bundle) => {
  try {
    track.product.removed({
      skus: getSkus(product),
      price: product.cost,
    });
  } catch (e) {
    console.error(e);
  }
};

export const getSkus = (product: Item) => {
  if (product.type === 'tuxedo' || product.type === 'suit') {
    // If product is a tuxedo or a suit, we need the jacket sku.
    const jacketSku = (product as Bundle).products!.map((p) => p.sku).filter((sku) => sku.charAt(0) === '1')[0];
    return [jacketSku];
  }

  return [product.sku];
};

/**
 *  If the name of the look can be a number e.g. '002', '003'
 *  AND there are no items AND there are no members on the look
 *  then the look can be deleted.
 */
export const lookisDeletable = (look: Look) =>
  look?.id && look.items?.length === 0 && look.bundles?.length === 0 && look.members?.length === 0;

export const lookAdded = (look: Look) => {
  try {
    if (Auth.user()) {
      track.look.created({
        look: look.name,
      });
    }
  } catch (e) {
    console.error(e);
  }
};

export const lookUpdated = (look: Look) => {
  try {
    if (Auth.user()) {
      track.look.updated({
        look: look.name,
      });
    }
  } catch (e) {
    console.error(e);
  }
};

export const lookDeleted = () => {
  try {
    if (Auth.user()) {
      // @todo Update to be incrementing property for a user as well.
      window.analytics.track('Look Deleted');
    }
  } catch (e) {
    console.error(e);
  }
};

export const startedFit = (data: object = {}) => {
  try {
    track.fit.started(data);
  } catch (e) {
    console.error(e);
  }
};

export const updatedFit = (data: object = {}) => {
  try {
    track.fit.updated(data);
  } catch (e) {
    console.error(e);
  }
};

export const completedFit = (data: object = {}) => {
  try {
    track.fit.completed({
      campaignId:
        getStringCookie('iterableEmailCampaignId') !== undefined
          ? Number(getStringCookie('iterableEmailCampaignId'))
          : null,
      ...data,
    });
  } catch (e) {
    console.error(e);
  }
};

export const updatedFitProfile = () => {
  try {
    track.fitProfile.updated();
  } catch (e) {
    console.error(e);
  }
};

export const completedFitProfile = () => {
  try {
    track.fitProfile.completed({
      campaignId:
        getStringCookie('iterableEmailCampaignId') !== undefined
          ? Number(getStringCookie('iterableEmailCampaignId'))
          : null,
    });
  } catch (e) {
    console.error(e);
  }
};

export const trackBrideGroomSignup = (partyRoleId: number) => {
  try {
    partyRoleId === 1 ? track.user.groomSignup() : track.user.brideSignUp();
    trackFn('Wedding Signup');

    if (window.gt.orgId === 1) {
      pixelEvent.brideGroomSignup();
    }
  } catch (e) {
    console.error(e);
  }
};

export const getProductImageUrl = (product: Item) => {
  const image = product.media!.filter((image) => {
    return image.label!.toLowerCase().includes('pdp_hero') || image.label!.toLowerCase().includes('layflat');
  });

  return image.length > 0 ? image[0].url : 'https://gentux.imgix.net/no-image.png';
};

export const trackCustomizationOfRandomLook = (bundle: Bundle, products: Product[]) => {
  return track.look.customized({
    bundle: {
      sku: bundle.sku,
      name: bundle.displayName ?? '',
    },
    products: products.reduce(
      (obj, product) => ({
        ...obj,
        [product.category?.toLowerCase() ?? '']: {
          sku: product.sku,
          name: product.displayName ?? '',
        },
      }),
      {}
    ),
  });
};

export const orderPlaced = (user: SegmentUser, eventId: string, orderId: string) =>
  track.order.completed(
    withTikTokClickId({
      email: user.email,
      phone: user.phone,
      phoneNumber: user.phone,
      eventId: eventId,
      order_id: orderId,
    })
  );

export const checkoutStarted = (user: SegmentUser, eventId: string) =>
  track.checkout.started(
    withTikTokClickId({
      eventId,
      email: user.email,
      phone: user.phone,
      phoneNumber: user.phone,
    })
  );

export const paymentInfoEntered = (user: SegmentUser) =>
  track.checkout.paymentInfo.entered(
    withTikTokClickId({
      email: user.email,
      phone: user.phone,
      phoneNumber: user.phone,
    })
  );

export const productsAdded = (user: SegmentUser, itemIds: number[]) =>
  track.product.added(
    withTikTokClickId({
      email: user.email,
      phone: user.phone,
      phoneNumber: user.phone,
      itemIds,
    })
  );

export const welcomePacketTrack = {
  request: (addressData: Object) => track.user.welcomePacketRequest(addressData),
  pageview: () => track.user.welcomePacketPageview(),
};

// HowDidYouHearAboutUs track event
export const howDidYouHearAboutUsTrack = {
  answered: (hdyhauData: Object) => track.user.howDidYouHearAboutUsAnswered(hdyhauData),
};

export const lookBuilderViewedTrack = (viewType: 'list' | 'exploded') => {
  const previewHasItems = Boolean(PreviewStore.productLayers.length > 0);

  const lookBuilderViewedData: LookBuilderViewedProps = {
    lookViewedType: viewType,
    lookViewedFillType: previewHasItems ? 'filled' : 'empty',
  };

  track.look.builder(lookBuilderViewedData);
};

export const participantAdded = (event: GTEvent, potentialMember?: PotentialMember) => {
  track.participant.added({
    eventId: event.id,
    name: potentialMember?.nickname,
    partyRoleName: potentialMember?.partyRole?.name,
  });
};

export const participantRemoved = (potentialMember?: PotentialMember, member?: Member) => {
  track.participant.removed({
    potentialMemberId: potentialMember?.id,
    email: member?.customer?.email ?? '',
    partyRoleName: potentialMember?.partyRole?.name,
  });
};

//Id property can be either a memberId or a potentialMemberId.
export const participantReassigned = (
  event: GTEvent,
  currentLook?: Look,
  previousLook?: Look,
  participant?: Participant
) => {
  track.participant.reassigned({
    eventId: event.id,
    previousLook: previousLook?.name,
    currentLook: currentLook?.name,
    id: participant?.id,
    email: participant?.email,
    partyRoleName: participant?.partyRoleName,
  });
};

/**
 * Called when share button is clicked
 */
export const shareLookBtnClickTrack = (shareData: Object) => {
  track.look.shareBtnClick(shareData);
};

/**
 * Called when "copy link" button is clicked
 */
export const sharedLookCopyLinkBtnClickTrack = (shareData: Object) => {
  track.look.shareCopyLinkClick(shareData);
};

/**
 * Called when "text me link to share" button is clicked
 */
export const sharedLookSmsBtnClickTrack = (shareData: Object) => {
  track.look.shareSmsClick(shareData);
};

/**
 * Called when the look has been shared
 */
export const sharedLookTrack = (shareData: Object) => {
  track.look.sharedLink(shareData);
};

/**
 * Look Edited
 */

export const lookEditedTrack = (data: LookEdited) => {
  track.look.edited(data);
};
