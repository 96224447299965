import { useRef, useState } from 'react';
import { type Image } from '../types';
import { asBlockClass } from '../utils/BEM';

type AnimatedCalloutProps = {
  title: string;
  subtext: string;
  img: Image;

  /**
   * (Optional) A name for the page or section that the callout instance belongs to
   *
   * This is appended to the block's class name, for example `looks` would result in
   * `.animated-callout--looks`
   */
  name?: string;
};

const AnimatedCallout = ({ title, subtext, img, name }: AnimatedCalloutProps) => {
  const [gifLoaded, setGifLoaded] = useState<boolean>(false);
  const timestampRef = useRef<number>(Date.now());

  const titleAnimation = {
    duration: '350ms',
    delay: '1000ms',
  };

  const subtextAnimation = {
    duration: '700ms',
    delay: '500ms',
  };

  const imgUrl = `${img.src}?loop=1&gtTimestamp=${timestampRef.current}`;
  const animateText = gifLoaded;

  const blockClass = asBlockClass('animated-callout', name);
  const iconClass = blockClass.withElement('icon');

  const infoBlockClass = asBlockClass('animated-callout-info', name);
  const titleClass = infoBlockClass.withElement('title');
  const subtextClass = infoBlockClass.withElement('subtext');

  return (
    <div className={`${blockClass}`}>
      <div className="flex gap-[25px]">
        <div className={`${iconClass} flex-shrink-0`}>
          <img
            className="h-[80px] w-[52px] xs:h-[88px] xs:w-[57.43px]"
            src={imgUrl}
            alt=""
            onLoad={() => setGifLoaded(true)}
            onError={() => setGifLoaded(false)}
          />
        </div>
        {gifLoaded && (
          <div className={`${infoBlockClass} flex-shrink-1 w-[300px] xs:w-[320px]`}>
            <h2
              style={{
                animationDuration: titleAnimation.duration,
                animationDelay: titleAnimation.delay,
              }}
              className={`${titleClass} ${
                animateText ? `animate-slideInFromLeft` : ''
              } font-['Roboto Condensed'] text-h2 normal-case mb-8 font-bold leading-[26px] text-gray-darker xs:leading-[30px]`}
            >
              {title}
            </h2>
            <p
              style={{
                animationDuration: subtextAnimation.duration,
                animationDelay: `calc(${titleAnimation.delay} + ${subtextAnimation.delay})`,
              }}
              className={`${subtextClass} ${
                animateText ? `animate-slideInFromTop` : ''
              } text-xs font-['Roboto'] font-normal leading-[13px] tracking-[0.24px] text-gray-darker xs:leading-[17px] xs:tracking-[0.3px]`}
            >
              {subtext}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default AnimatedCallout;
