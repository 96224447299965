/**
 * Returns an object that represents a BEM block class
 *
 * These objects can be used to construct additional classes that represent the
 * states the block can be in, elements that can belong to the block, etc.
 *
 * @see [`bem.md`](../../documentation/bem.md) for more information on the builder
 *  methods, as well as what we use these classes for
 */
export const asBlockClass = (name: string, modifier?: string) => new Block(name, modifier);

abstract class Entity {
  constructor(protected name: string, protected modifier?: string) {}

  abstract getBase: () => string;

  toString = () => {
    let className = this.getBase();

    if (this.modifier) {
      className += '--' + this.modifier;
    }

    return className;
  };
}

class Block extends Entity {
  getBase = () => {
    return this.name;
  };

  withElement = (element: string, modifier?: string) => {
    return new Element(this, element, modifier);
  };

  withModifier = (modifier: string) => {
    return new Block(this.name, modifier);
  };
}

class Element extends Entity {
  protected block: Block;

  constructor(block: Block, name: string, modifier?: string) {
    super(name, modifier);
    this.block = block;
  }

  getBase = () => {
    return `${this.block}__${this.name}`;
  };

  withModifier = (modifier: string) => {
    return new Element(this.block, this.name, modifier);
  };
}
