import Line from '../components/Line';
import { PropsWithChildren } from 'react';
import { asBlockClass } from '../utils/BEM';

type PageHeaderProps = PropsWithChildren<{
  header: string;

  /**
   * (Optional) A name for the page that the component instance belongs to
   *
   * This is appended to the block's class name, for example `looks` would result in
   * `.page-header--looks`
   */
  pageName?: string;
}>;

const PageHeader = ({ header, pageName, children }: PageHeaderProps) => {
  const blockClass = asBlockClass('page-header', pageName);
  const headerClass = blockClass.withElement('header');
  const lineClass = blockClass.withElement('header-line');
  const contentClass = blockClass.withElement('header-content');

  return (
    <div className="row my-32 md:my-64">
      <div className="col-span-12 xs:col-span-5 lg:col-span-3">
        <h1 className={`normalcase text-h2-display ${headerClass}`}>{header}</h1>
        <Line className={`xs:!mb-0 ${lineClass}`} />
      </div>
      <div className={`col-span-12 xs:col-span-7 lg:col-span-5 ${contentClass}`}>{children}</div>
    </div>
  );
};

export default PageHeader;
